import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Grid, Paper,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSpring, animated } from 'react-spring'
import RequestQuoteForm from '../components/RequestQuoteForm';
import BackgroundVideo from '../components/BackgroundVideo';
import Gallery from '../components/Gallery';
import ImagesProvider from '../contexts/ImagesProvider';
import FeedbackForm from '../components/FeedbackForm';
import FeedbacksProvider from '../contexts/FeedbacksProvider';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    spacing: 0,
    justify: 'space-around',
  },
  introContainer: {
    marginTop: '120px',
  },
  formContainer: {
    paddingLeft: '0%',
    marginTop: '50px',
    paddingBottom: '50px',
  },
  feedbacksContainer: {
    marginTop: '50px',
  },
  introTitle: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px',
    paddingRight: 20,
    paddingLeft: 20,
    textAlign: 'center',
    marginTop: '2%',
  },
  introDescription: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '17px',
    paddingRight: 20,
    paddingLeft: 20,
  },
  introDescription2: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px'
  },
  listItem: {
    height: '30px',
  },
  compareImageBox: {
    marginTop: '20px',
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      padding: '50px'
    },
    padding: '20px'
  }
}))

export default function HomePage() {
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 500 } })
  const classes = useStyles()
  const services = [
    "Lawn care", "Pressure Washing"
  ]
  const [showDownIcon, setShowDownIcon] = useState(true);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -250) {
      setShowDownIcon(false)
    } else if (currPos.y === 0) {
      setShowDownIcon(true)
    }
  });

  const handleScroll = () => {
    window.scroll({
      top: document.body.offsetHeight - 200,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <animated.div
      style={props}>
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        alignItems="center"
        direction="row">

        <Grid
          xs={12}
          item
          className={classes.introContainer}>
          <Paper style={{ padding: 16 }}>
            <p className={classes.introDescription} style={{ marginTop: '20px', fontSize: '20px' }}>
              Henry Pham's Landscaping & Gardening offers general services at affordable prices. We offer FREE ESTIMATES.<br /><br />
              These are the services we offer:
            </p>
            <br />
            <Grid
              container
              direction="row"
            >
              <Grid
                xs={12}
                md={6}
                style={{ padding: 20 }}
                justifyContent="center"
                alignItems="center"
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    style={{ width: 220, height: 220, alignSelf: 'center' }}
                    alt="lawn_care_icon"
                    src="https://d2hmgpwhxrgxc0.cloudfront.net/lawn_care_icon.png"
                  />
                </div>
                <p className={classes.introDescription2}>
                  Mowing, Edging, Weed removal & Feritizing<br />
                  Pruning, Trimming & Tree Cutting<br />
                  Beauty Bark & Top Soil<br />
                  Lawn Installation, Reseed & Sod<br />
                  Leave Cleaning & Brush clearing<br />
                  Flower Beds, Weed Cleaning, Moss Control & Gravel<br />
                  Demo, salvage & junk removal
                  Install Fences & retaining Walls.
                </p>
              </Grid>
              <Grid
                xs={12}
                md={6}
                style={{ padding: 20 }}
                justifyContent="center"
                alignItems="center"
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    style={{ width: 220, height: 220, alignSelf: 'center' }}
                    alt="lawn_care_icon"
                    src="https://d2hmgpwhxrgxc0.cloudfront.net/pressure_washing_icon.png"
                  />
                </div>
                <p className={classes.introDescription2}>Wooden decks, driveways & sidewalks.</p>
              </Grid>
            </Grid>
            <br />
            <p className={classes.introDescription}>
              Available any time. Give us a call/text at <a href="tel:+1-206-849-6209" style={{ color: 'black' }}>(206) 849-6209</a>, or use the form below. Thank you!
            </p>
          </Paper>
        </Grid>

        <Grid
          xs={12}
          item
          className={classes.formContainer}>
          <RequestQuoteForm services={services} />
        </Grid>

        <Grid
          style={{ paddingBottom: '50px' }}
          item
          xs={12}>
          <Paper
            className={classes.paper}
          >
            <h1
              style={{ margin: 'auto', textAlign: 'center', marginBottom: '50px' }}>
              Gallery
            </h1>
            <ImagesProvider>
              <Grid
                container
                justifyContent="center"
                direction="row">
                <Grid
                  xs={12}
                  item>
                  <Gallery />
                </Grid>
              </Grid>
            </ImagesProvider>
          </Paper>
        </Grid>

        <Grid
          style={{ paddingBottom: '50px' }}
          item
          xs={12}>
          <Paper
            className={classes.paper}
          >
            <FeedbacksProvider>
              <FeedbackForm />
            </FeedbacksProvider>
          </Paper>
        </Grid>

        {
          showDownIcon &&
          <IconButton
            onClick={handleScroll}
            color="primary"
            style={{ position: 'fixed', bottom: '25px' }}>
            <KeyboardArrowDownIcon style={{ fontSize: 60 }} />
          </IconButton>
        }

        <BackgroundVideo />
      </Grid>
    </animated.div >
  );
}
