import React, { useContext } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Grid, Button, Typography
} from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import FeedbacksContext from "../contexts/FeedbacksContext";
import emailjs from 'emailjs-com';


const onSubmit = async (values, form, addFeedback) => {
  try {
    const data = {
      feedbackName: values.name,
      feedback: values.testimonial,
      show: false,
    };

    addFeedback(data);

    Object.keys(values).forEach(key => {
      form.change(key, undefined);
      form.resetFieldState(key);
    });

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_FEEDBACKS_TEMPLATE_ID,
      data, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((result) => {
        toast.success('Thank you for your feedback.', {
          duration: 4000,
          style: {
            backgroundColor: 'green',
            color: 'white',
            fontWeight: 'bold'
          }
        })
      }, (error) => {
        console.log(error.text);
      });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.testimonial) {
    errors.testimonial = 'Required';
  }
  return errors;
};

export default function FeedbackForm() {
  const feedbacksContext = useContext(FeedbacksContext);
  const { addFeedback } = feedbacksContext;

  return (
    <Form
      onSubmit={(values, form) => onSubmit(values, form, addFeedback)}
      validate={validate}
      render={({ handleSubmit, reset, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid
              item
              xs={12}>
              <Typography variant="h6">
                We value your business and appreciate your feedback as we continue to strive to provide you an exellence experience.
              </Typography>
              <Typography>
                Please complete the form and let us know how we are doing. Thank you.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                name="name"
                component={TextField}
                type="text"
                label="Name/Nickname"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                required
                name="testimonial"
                component={TextField}
                multiline
                label="Testimonial"
              />
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                Submit review
              </Button>
            </Grid>
          </Grid>
          <Toaster />
        </form>
      )}
    />
  )
}