import React from 'react';
import PropTypes from 'prop-types';
import { Link, } from "react-router-dom";
import {
  AppBar, Toolbar, Button,
  Hidden, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import logo from '../assets/logo256.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  appBarItem: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    marginLeft: 15,
    marginRight: 15,
  },
  link: {
    color: 'black',
    textDecoration: 'none'
  },
  linkActive: {
    color: 'black',
    textDecoration: 'underline'
  },
  drawerPaper: {
    width: drawerWidth
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  drawerItem: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'black'
  },
  drawerItemActive: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'black',
    textDecoration: 'underline'
  },
}))

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function NavBar(props) {
  const classes = useStyles();

  const handleScroll = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          position="fixed"
          className={classes.appBar}
          elevation={0}>
          <Toolbar>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center">
              <Hidden smDown>
                <Link
                  to="/"
                  className={classes.link}>
                  <Grid item>
                    <Button
                      onClick={handleScroll}
                      color="inherit"
                      className={classes.appBarItem}
                    >
                      Henry Pham's Landscaping & Gardening
                    </Button>
                  </Grid>
                </Link>
              </Hidden>
              <Hidden mdUp>
                <Link
                  to="/"
                  className={classes.link}
                  style={{ marginLeft: 'auto', marginRight: 'auto', }}
                >
                  <Grid item>
                    <Button
                      onClick={handleScroll}
                      color="inherit"
                      className={classes.appBarItem}
                    >
                      Henry Pham's Landscaping & Gardening
                    </Button>
                  </Grid>
                </Link>
              </Hidden>
            </Grid>
            <Hidden smDown>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
                <Link
                  className={classes.link}>
                  <Grid item>
                    <a style={{ fontSize: '18px', textDecoration: 'none', color: 'black' }}
                      href="tel:+1-206-849-6209">
                      <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Call us:</span> (206) 849-6209
                    </a>
                  </Grid>
                </Link>
              </Grid>
            </Hidden>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  )
}